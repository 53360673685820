(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables

         //Header scroll animation
        $(document).scroll(function() {
            if ($(document).scrollTop() >= $('#header').outerHeight()) {
                $('#header').addClass('is--sticky');
            } else {
                $('#header').removeClass('is--sticky');
            }
        });

        // mobile header scroll animation

        $(window).resize(function(){
            if ($(window).width() <= 800){  
               $('#header').addClass('is--sticky');
            }   
        });

        // toggle menu

        $('#menu').click(function(e) {
            e.preventDefault();
            console.log("heu njomzaaaa");
            $(this).toggleClass("active");

            if($(this).hasClass("active")){
                $('#menu_content').addClass("is--active");
                $('#logo').addClass('is--hidden');
                $('#moto').addClass('is--hidden');
               
            }else{
                $('.menu_content').removeClass("is--active");
                $('#logo').removeClass('is--hidden');
                $('#moto').removeClass('is--hidden');
            }
            lockScroll();
        });

        $('.menu_purple').click(function(e) {
            e.preventDefault();
            $(this).toggleClass("active");

            if($(this).hasClass("active")){
                $('#menu_content').addClass("is--active");
                $('#logo').addClass('is--hidden');
                $('#moto').addClass('is--hidden');
               
            }else{
                $('.menu_content').removeClass("is--active");
                $('#logo').removeClass('is--hidden');
                $('#moto').removeClass('is--hidden');
            }
            lockScroll();
        });
        
        function lockScroll() {
            if ($('body').hasClass('lock_scroll')) {
                $('body').removeClass('lock_scroll');
            } else {
                $('body').addClass('lock_scroll');
            }
        }

        // AOS plugin
        AOS.init();

        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d");
    });
}(window.jQuery, window, document));
